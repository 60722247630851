import React from 'react';
import Footer from '../components/Footer';
import Form from '../components/Form';

import Header from '../components/Header';
import Intro from '../components/Intro';

const Home = () => (
  <>
    <Header />
    <Intro />
    <Form />
    <Footer />
  </>
);

export default Home;
