import { styled } from "@mui/material";

const StyledFieldset = styled('fieldset')({
  border: `1px solid #ccc`,
  borderRadius: '1rem',
  padding: '2rem',
  marginBottom: '2rem',
  '> legend': {
    background: 'white',
    marginLeft: '-0.5rem',
    padding: '0 0.5rem',
    fontFamily: 'neo_sans',
    fontWeight: 'bold',
  }
});

export default StyledFieldset;
