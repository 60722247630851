import { Container, Grid, styled } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MissionProjects from './MissionProjects';
import MissionDivers from './MissionDivers';
import MissionFiles from './MissionFiles';
import MissionRiskAssessment from './MissionRiskAssessment';
import StyledFieldset from './StyledFieldset';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const StyledContainer = styled(Container)({
  marginTop: '2rem',
});

const Mission = ({ application }) => {
  const { daten } = application;
  const { t } = useTranslation();
  const link = `${BASE_URL}/antrag/${daten?.antrag_hash}`;

  console.log(daten);

  return (
    <StyledContainer>
      <StyledFieldset>
        <legend>{t('mission.title')}</legend>
        <Grid container>
          <Grid item xs={12} sm={3}>
            {t('mission.lastUpdate')}
          </Grid>
          <Grid item xs={12} sm={9}>
            {daten?.antrag_timestamp}
          </Grid>
          <Grid item xs={12} sm={3}>
            {t('mission.link')}
          </Grid>
          <Grid item xs={12} sm={9}>
            <a href={`${link}`}>{`${link}`}</a>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: '1rem' }}>
          <Grid item xs={12} sm={3}>
            {t('form.plannedMissionStart')}
          </Grid>
          <Grid item xs={12} sm={9}>
            {daten?.antrag_geplant_beginn}
          </Grid>
          <Grid item xs={12} sm={3}>
            {t('form.plannedMissionEnd')}
          </Grid>
          <Grid item xs={12} sm={9}>
            {daten?.antrag_geplant_ende}
          </Grid>
        </Grid>
        <Grid container sx={{ mt: '1rem' }}>
          <Grid item xs={12} sm={3}>
            {t('form.shortDescription')}
          </Grid>
          <Grid item xs={12} sm={9}>
            {daten?.antrag_kurzbezeichnung}
          </Grid>
        </Grid>
      </StyledFieldset>
      <StyledFieldset>
        <legend>{t('mission.applicant')}</legend>
        <Grid container>
          <Grid item xs={12} sm={3}>
            {t('form.fullname')}
          </Grid>
          <Grid item xs={12} sm={9}>
            {daten?.antrag_antragsteller}
          </Grid>
          <Grid item xs={12} sm={3}>
            {t('form.email')}
          </Grid>
          <Grid item xs={12} sm={9}>
            {daten?.antrag_antragsteller_email}
          </Grid>
        </Grid>
      </StyledFieldset>
      <MissionProjects projects={daten?.antrag_projekte} />
      <StyledFieldset>
        <legend>{t('form.missionDescription')}</legend>
        <div dangerouslySetInnerHTML={{ __html: daten?.antrag_projektbeschreibung }} />
      </StyledFieldset>
      <StyledFieldset>
        <legend>{t('form.diveSiteLocation')}</legend>
        <div dangerouslySetInnerHTML={{ __html: daten?.antrag_tauchgebiet }} />
      </StyledFieldset>
      <StyledFieldset>
        <Grid container>
          <Grid item xs={12} sm={9}>
            {t('form.numberOfPlanedDives')}
          </Grid>
          <Grid item xs={12} sm={3}>
            {daten?.antrag_anzahl_tauchgaenge}
          </Grid>
          <Grid item xs={12} sm={9}>
            {t('form.maximumDiveDepth')}
          </Grid>
          <Grid item xs={12} sm={3}>
            {daten?.antrag_max_einsatztiefe}
          </Grid>
          <Grid item xs={12} sm={9}>
            {t('form.maximumDiveTime')}
          </Grid>
          <Grid item xs={12} sm={3}>
            {daten?.antrag_max_einsatzdauer}
          </Grid>
          <Grid item xs={12} sm={9}>
            {t('form.plannedDecompressionDives')}
          </Grid>
          <Grid item xs={12} sm={3}>
            {daten?.antrag_dekompressionstauchgaenge}
          </Grid>
          <Grid item xs={12} sm={9}>
            {t('form.breathingGas.label')}
          </Grid>
          <Grid item xs={12} sm={3}>
            {daten?.antrag_atemgas}
          </Grid>
        </Grid>
      </StyledFieldset>
      <StyledFieldset>
        <legend>{t('mission.plannedUnderwaterWork')}</legend>
        <div dangerouslySetInnerHTML={{ __html: daten?.antrag_aufgaben }} />
      </StyledFieldset>
      <StyledFieldset>
        <legend>{t('mission.divers')}</legend>
        <MissionDivers data={daten} />
      </StyledFieldset>
      <MissionFiles id={daten?.antrag_id} />
      <StyledFieldset>
        <legend>{t('mission.furtherComments')}</legend>
        <div dangerouslySetInnerHTML={{ __html: daten?.antrag_bemerkungen }} />
      </StyledFieldset>
      <MissionRiskAssessment data={daten} />
      <StyledFieldset>
        <legend>{t('mission.specialDefinitions')}</legend>
        <div dangerouslySetInnerHTML={{ __html: daten?.antrag_besonderegenehmigungen }} />
      </StyledFieldset>
    </StyledContainer>
  );
};

Mission.propTypes = {
  application: PropTypes.object,
};

Mission.defaultProps = {
  application: {},
};

export default Mission;