import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StyledFieldset from './StyledFieldset';

const MissionProjects = ({ projects }) => {
  const { t } = useTranslation();
  return projects.length > 0 && (
    <StyledFieldset>
      <legend>{t('form.projects')}</legend>
      <TableContainer component={Box}>
        <Table size="small" aria-label={t('form.projectTable')}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{t('form.shortName')}</TableCell>
              <TableCell>{t('form.applicant')}</TableCell>
              <TableCell>{t('form.mailAddress')}</TableCell>
              <TableCell>{t('form.costCentre')}</TableCell>
              <TableCell>{t('form.invoiceAddress')}</TableCell>
              <TableCell>{t('form.comment')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map((p, index) => (
              <TableRow key={`row${index}`}>
                <TableCell>{p.projekt_nummer}.</TableCell>
                <TableCell>{p.projekt_kurzname}</TableCell>
                <TableCell>{p.projekt_antragsteller}</TableCell>
                <TableCell>{p.projekt_mailadresse}</TableCell>
                <TableCell>{p.projekt_kostenstelle}</TableCell>
                <TableCell>{p.projekt_rechnungsadresse}</TableCell>
                <TableCell>{p.projekt_kommentar}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledFieldset>
  );
};

MissionProjects.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      shortName: PropTypes.string,
      applicant: PropTypes.string,
      mailAddress: PropTypes.string,
      costCentre: PropTypes.string,
      invoiceAddress: PropTypes.string,
      comment: PropTypes.string,
    }),
  ),
};

MissionProjects.defaultProps = {
  projects: [],
};

export default MissionProjects;
